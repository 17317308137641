import { ToastError } from '@/components/toastify';
import axios from 'axios';
import { signOut } from '../helpers';
import Cookies from 'js-cookie';

const BASE_URL = process.env.NEXT_PUBLIC_API_URL;
export const api = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const {
      response: { status },
    } = error;
    if (status === 401) {
      ToastError('Your session has expired.');
      signOut();
    }

    console.log(error);
    return Promise.reject(error);
  }
);

api.defaults.headers.common['Authorization'] =
  typeof window !== 'undefined' &&
  (Cookies.get('token') === undefined
    ? null
    : `Bearer ${Cookies.get('token')}`);

export function setAuthorization(token = null) {
  api.defaults.headers.common['Authorization'] =
    token === null ? token : `Bearer ${token}`;
}

export function removeAuthorization() {
  //for Logout
  setAuthorization(null);
}
